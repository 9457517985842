.policy {
    width: 1280px;
    height: 100%;
    margin: auto;
}
.policy.dark-mode {
    color: white !important;
    background-color: transparent !important;
}
.policy .container {
    padding-inline: 15px;
}
.policy .container h1 {
    color: #3B83FF;
    text-align: center;
}
.policy .container h2 {
    display: flex;
    gap: 10px;
}
.policy .container div {
    color: #3B83FF;    
}
@media only screen and (max-width: 1280px) {
    .policy {
        width: 100%;
    }
 }
 @media only screen and (max-width: 780px) {
    .policy {
        position: relative;
        padding-top: 1px;
    }
 }
 @media only screen and (max-width: 660px) {
    .policy {
        padding-top: 85.33333px;
    }
  }