footer {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #3B83FF;
    background-color: white;
    padding-bottom: 20px;
    transition: 0.3s;
    z-index: -4;
}
footer hr {
    color: #3B83FF;
    background-color: #3B83FF;
    border: 0;
    margin: auto;
    height: 2px;
    border-radius: 100px;
    width: 90%;
}

footer img {
    width: 85px;
    height: 85px;
}

footer .container {
    padding-block: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin: auto;
}

footer a {
    color: #3B83FF;
}

@media only screen and (max-width: 1280px) {
    footer {
        width: 90%;
    }
 }