/* PageTransitions.css */
.fade-enter {
  position: absolute;
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: 200ms ease-in 200ms; /* Added transition delay */
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out;
}
