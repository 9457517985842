.dark-mode {
    transition: background-color 0.3s color 0.3s !important;
    background-color: rgb(26, 26, 26) !important;
    color: white !important;
    .square {
        background-color: rgb(26, 26, 26) !important;
        }
    a {
        color: white !important;
    }
}


header {
    display: flex;
    flex-direction: column;
    background-color: transparent;
}

header a {
    display: flex;
    text-decoration: none;
    color: #3B83FF;
}

/* header .topHeaderBg {
    width: 100%;
    background-color: #3B83FF;
} */

header .headerBg {
    transition: 0.3s;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 15px 15px rgba(59, 131, 255, 0.454);
}

/* header .topHeader {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1280px;
    height: 23px;
}

header .topHeader div {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
} */

/* header .topHeader button {
    border: 0px;
    padding: 0px;
} */
header .topHeader .icon {
    padding-right: 5px;
}

/* header .topHeader .button {
    align-items: center;
    border: 0px;
    padding: 0px;
    height: 100%;
    text-decoration: none;
    color: #3B83FF;
    background-color: transparent;
    padding-inline: 5px;
    transition: 0.3s;
}

header .topHeader button {
    cursor: pointer;
} */

header .header {
    padding-block: 10px;
    width: 1280px;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .header .options {
    position: relative;
    display: flex;
    justify-content: right;
}
header .header .darkButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 0;
    transition: all 0.3s ease;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    position: relative;
    font-size: 30px;

}
header .header .darkButton .darkIcon {
    margin: auto;
    transition: 0.3s ease;
    position: absolute;
    color: rgb(0, 0, 0);
    background-color: white;
    transition-delay: 150ms;
    scale: 100%;
    border-radius: 0%;
}
header .header .darkButton .sunIcon {
    margin: auto;
    transition: 0.3s ease;
    scale: 0%;
    color: rgb(26, 26, 26);
    position: absolute;
}
header .header .darkButton.dark-mode{
    .darkIcon {
        scale: 40%;
        transition-delay: 0ms;
        transform: rotate(180deg);
        color: rgb(255, 255, 255);
        border-radius: 100%;
        background-color: rgb(255, 255, 255);
    }
    .sunIcon {
    scale: 112%;
    transition-delay: 150ms;
    transform: rotate(180deg);
    color: white;
    background-color: transparent !important;
    }
    background-color: transparent !important;
}
header .header .buttons {
    display: flex;
    gap: 100px;
    align-items: center;
    justify-content: right;
    width: 70%;
}

header .header .buttons .button {
    border: 0;
    background-color: transparent;
    color: #3B83FF;
    height: 45px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
}
header .header .buttons .button.dark-mode {
    color: #3B83FF !important;
    background-color: transparent !important;
}
header .header .buttons .shop {
    font-weight: 700;
    background-color: #3B83FF;
    border-radius: 10px;
    padding: 0 15px;
    color: white;
    /* background-color: #FFD527; */
}

header .header .buttons .button hr {
    width: 0%;
    height: 2px;
    border: 0px;
    transition: 0.3s;
    rotate: -180deg;
    position: absolute;
    bottom: -7px;
    color: #3B83FF;
    background-color: #3B83FF;
}

header .header .buttons .button:hover hr {
    width: 80%;
}

header .header .logo {
    z-index: 2;
    display: flex;
    align-items: center;
}

header .header .logoVid {
    position: relative;
    right: 20px;
    width: 400px;
}

header .header .logoImg {
    position: relative;
    border-radius: 10px;
    width: 85px;
}

header .header .select {
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 200px;
    height: 70px;
    z-index: 1;
    background-color: white;
}

header .header .select input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

header .header .select hr {
    pointer-events: none;
    position: absolute;
    margin: 0;
    width: 30px;
    height: 2px;
    transition: 0.3s;
    color: #3B83FF;
    background-color: #3B83FF;
    border: 0;
}

header .header:has(.select input:checked) .button {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0px);
}

header .header .select hr:nth-child(2) {
    transform: translateY(-10px);
}

header .header .select input:checked~hr:nth-child(2) {
    transform: translateY(0px);
    rotate: 45deg;
}

header .header .select input:checked~hr:nth-child(3) {
    width: 0px;
}

header .header .select hr:nth-child(4) {
    transform: translateY(10px);
}

header .header .select input:checked~hr:nth-child(4) {
    rotate: -45deg;
    transform: translateY(0px);
}

@media only screen and (max-width: 1280px) {
    header .header {
        width: 100%;
    }

    header .header .buttons {
        display: flex;
        gap: 50px;
        align-items: center;
        justify-content: right;
        width: 100%;
        padding: 15px;
    }

    header .header .logo {
        padding-left: 15px;
        display: flex;
        align-items: center;
    }
}

@media only screen and (max-width: 780px) {

    header .header .darkButton {
        position: absolute;
        margin: 0;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    header .header .options {
        position: relative;
        display: flex;
        justify-content: right;
        width: 200px;
    }

    header .header .select {
        display: flex;
        flex-direction: column;
        gap: 7px;
    }

    header .header .buttons .button:hover {
        background-color: white;
        color: #3B83FF;
    }
    header .header .buttons .shop:hover {
        background-color: #3B83FF;
        color: white;            
    }
    header .header .buttons .button:nth-child(1) {
        transition-delay: 400ms;
    }

    header .header .buttons .button:nth-child(2) {
        transition-delay: 300ms;
    }

    header .header .buttons .button:nth-child(3) {
        transition-delay: 200ms;
    }

    header .header .buttons .button:nth-child(4) {
        transition-delay: 100ms;
    }

    header .header:has(.select input:checked) .options {
        transition-delay: 0ms;
        width: 200px;

        .select {
            width: 100%;
            transition-delay: 300ms;
            border-radius: 0%;
        }

        .button:nth-child(1) {
            transition-delay: 300ms;
        }

        .button:nth-child(2) {
            transition-delay: 400ms;
        }

        .button:nth-child(3) {
            transition-delay: 500ms;
        }

        .button:nth-child(4) {
            transition-delay: 600ms;
        }
    }

    header .header .options {
        transition: 0.3s;
        transition-delay: 750ms;
        right: 15px;
        position: relative;
        display: flex;
        justify-content: center;
        width: 70px;
    }

    header .header .select {
        transition: 0.15s;
        z-index: 2;
        justify-content: center;
        border-radius: 100%;
        width: 70px;
        height: 70px;
        background-color: #3B83FF;
        transition-delay: 600ms;
        /* Make sure this rule also has the delay */
    }

    header .header .options .select hr {
        color: white;
        background-color: white;
    }

    header .header .buttons {
        transition: all 0.3s ease;
        pointer-events: none;
        position: absolute;
        z-index: 1;
        top: 70px;
        flex-direction: column;
        gap: 0px;
        width: 200px;
        padding: 0px;
    }

    header .header .buttons .button {
        opacity: 0;
        background-color: rgb(255, 255, 255);
        transform: translateY(-80px);
        width: 100%;
        height: 70px;
        transition: 0.3s;
    }
    header .header .buttons .button.dark-mode {
        background-color: rgb(26, 26, 26) !important;
    }

    header .header .buttons .button hr {
        display: none;
    }



    header .header .buttons .button:hover {
        hr {
            width: 0%;
        }
    }

    header .header .buttons .shop {
        font-weight: 700;
        background-color: #3B83FF;
        border-radius: 0px;
        padding: 0 15px;
        color: white;
        /* background-color: #FFD527; */
    }
}
    @media only screen and (max-width: 660px) {
        header {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            /* Take up the entire viewport width */
            /* Take up the entire viewport height */
            z-index: 5;
            transition: 0.3s ease-in-out;
        }
    }

