.follow {
    display: flex;
    z-index: -1;
    position: fixed;
    margin: -30px;
    width: 110vw;
    height: 100vh;
    top: -10px;
}

.follow .followBackground {
    transition: 0.3s;
    position: absolute;
    z-index: 1;
    margin: 0;
    left: 47%;
    transform: translate(-50%, 0%);
    width: 1280px;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    filter: blur(10px);
}
.follow .followBackground.dark-mode {
    background-color: rgb(26, 26, 26, 0.8) !important;
}

/* The circle that follows the mouse */
.circle {
    /* Allows positioning based on mouse coordinates */
    z-index: -1;
    position: absolute;
    top: -230px;
    left: -200px;
    width: 500px;
    height: 500px;
    background-color: #3B83FF;
    border-radius: 50%;
    pointer-events: none;
    /* Prevent the circle from interfering with other elements */
    filter: blur(10px);
    box-shadow: 0 0 0px 0px rgba(59, 131, 255, 0.5);
}

.squares {
    transition: 0.3s;
    z-index: 0;
    top: 5.5px;
    display: flex;
    flex-wrap: wrap;
    width: 120vw;
    height: 100vh;
    position: fixed;
    gap: 5px;
    background-color: rgb(59, 132, 255, 0);
    
}

.square {
    transition: 0.3s;
    z-index: 10;
    opacity: 1;
    transform: skewX(-10deg);
    border-radius: 15px;
    height: 250px;
    width: 200px;
    background-color: white;
}

.square:hover {
    opacity: 1;
}
@media only screen and (max-width: 1280px) {
    .follow .followBackground {
        left: 53%;
        transform: translate(-50%, -6%);
        width: 90%;
    }
}
@media only screen and (max-width: 660px) {
    .follow .squares {
        opacity: 0;
        display: none;
    }
    .follow .followBackground.dark-mode {
        width: 100%;
        height: 100%;
        background-color: rgb(26, 26, 26, 1) !important;
        position: absolute;
        z-index: 1;
        margin: 0;
        left: 0;
        top: 100px;
        transform: translate(0, 0);
        width: 1280px;
        height: 100vh;
        filter: blur(0px);
    }
}