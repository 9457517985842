.messages {
    transition: 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    z-index: 10;
    position: fixed;
    right: 4%;
    top: 4%;
}
.messages .message {
    overflow: hidden;
    transition: 0.3s;
    opacity: 0;
    height: 100%;
    background-color: transparent;
    padding-bottom: 15px;
    transform: translateY(-50px);
    animation: fadeIn 0.5s forwards,
     fadeOut 0.5s 7s forwards
     ;
}
.messages .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    height: 100%;
    background-color: red;
    border-radius: 7px;
    color: white;
    padding-top: 10px;
}
.messages .container p {
    margin: 0;
    padding-inline: 10px;
    padding-bottom: 10px;
}
.messages .container hr {
    margin: auto;
    color: white;
    background-color: white;
    border: 0;
    height: 3px;
    border-radius: 15px;
    width: 100%;
    animation: length 7.5s ease;
}
.messages .fade-out {
    height: 0%;
    padding: 0px;
    animation: fadeOut 0.5s forwards;
  }
@keyframes length {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
}
  @keyframes fadeIn {
    from {
      opacity: 0; /* Start fully transparent */
      transform: translateY(-50px); /* Move slightly up */
    }
    to {
      opacity: 1; /* Final state */
      transform: translateY(0); /* Reset the upward movement */
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1; /* Start fully visible */
      padding-bottom: 15px;
      max-height: 500px;
    }
    to {
      opacity: 0; /* Final state for fade-out */
      padding-bottom: 0px;
      transform: translateY(0px); /* Optional: add downward movement */
      max-height: 0px;
    }
  }

.messages .fade-in {
    opacity: 1;
}
.messages .top {
    padding-inline: 10px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.messages .top .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.messages .top .error-icon {
    font-size: 23px;
}
.messages .top .icon .title {
    margin: 0;
    white-space: nowrap;
}
.messages .top button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    height: 20px;
    width: 20px;
    padding: 5px;
    border: 0;
    outline: 1px solid white;
    border-radius: 5px;
    color: white;
}

/* SUCCESS */

.messages .success .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    height: 100%;
    background-color: rgb(0, 160, 0);
    border-radius: 7px;
    color: white;
    padding-top: 10px;
}